import React from "react";
import Layout from "./../components/Layout";
import CommunityHero from "./../components/CommunityHero";
import TeamCardHolder from "./../components/TeamCardHolder";
import { useStaticQuery, graphql } from "gatsby";

// N.b. this is very similar to what's in pages/team! But not exactly the same.
// If there are changes, make sure they're reflected!

const TeamFrontPage = ({ pageContext }) => {
  const mainTeamQuery = useStaticQuery(graphql`
    query {
      people: allCraftOurPeopleOurPeopleEntry {
        nodes {
          title
          ourPeopleHeadshot {
            ... on Craft_ourPeopleAssets_Asset {
              wideImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 295) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          slug
          ourPeopleFacebook
          ourPeopleTwitter
          ourPeopleTitle
          ourPeopleLinkedin
          ourPeopleInstagram
          richTextField
          communityMemberContactDetails {
            method
            value
          }
        }
      }
      recommendations: allCraftRecommendationRecommendationEntry {
        edges {
          node {
            title
            recommendationName {
              slug
              title
            }
            recommendationEvents {
              ... on Craft_event_event_Entry {
                title
                eventEndDateTime
                eventStartDateTime
                eventShortDescription
                slug
                eventType
                headerImage {
                  ... on Craft_editorialAssets_Asset {
                    cardImage: localFile {
                      publicURL
                      childImageSharp {
                        fixed(width: 290) {
                          src
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      teamPage: craftTeamTeamEntry {
        title
        subtitle
      }
    }
  `);
  const ourPeople = mainTeamQuery.people.nodes;
  const allTheRecommendations = mainTeamQuery.recommendations.edges.map(
    (x) => x.node
  );
  const { title, subtitle } = mainTeamQuery.teamPage;
  const thisPerson = ourPeople.filter((x) => x.slug === pageContext.slug)[0];

  return (
    <Layout backgroundColor={"var(--white)"} title={title}>
      <div>
        <CommunityHero topLine={title} text={subtitle} color={"var(--green)"} />
        <TeamCardHolder
          cards={ourPeople}
          recommendations={allTheRecommendations}
          openTo={thisPerson}
        />
      </div>
    </Layout>
  );
};

export default TeamFrontPage;

// export const pageQuery = graphql`
//   query($id: String!) {
//     craftEntryInterface(slug: { eq: $id }) {
//       ... on Craft_ourPeople_ourPeople_Entry {
//         id
// 				title
//         ourPeopleHeadshot {
//           url
//         }
//         slug
//         ourPeopleFacebook
//         ourPeopleTwitter
//         ourPeopleTitle
//         ourPeopleLinkedin
//         ourPeopleInstagram
//         richTextField
//         communityMemberContactDetails {
//           method
//           value
//         }
//       }
//     }
//   }
// `;
